import React from "react";
import frame from "../../assets/image/frame-agenda.png";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";

export function Dresscode({ onLoad, dressCode }) {
  return (
    <>
      <section id="dresscode">
        <div className="container">
          <div className="title text-center">Dress code</div>
          <div className="row mt-5 justify-content-center">
            {onLoad ?
              <>
                {['', '', ''].map((v, i) => (
                  <div className="col-12 col-md-4" key={`skeletondresscode${i}`}>
                    <div className="frame-dresscode" style={{ overflow: 'unset' }}>
                      <Skeleton width={'100%'} borderRadius={5} height={'100%'}></Skeleton>
                    </div>
                  </div>
                ))}
              </>
              : <>
                {dressCode.map((value, index) => (
                  <div className="col-12 col-md-4" key={`dresscode${index}`}>
                    <a href={index === 8 ? imageUrl.file('MSA OUTFIT GUIDE.pdf') : 'javascript:void(0)'} target={index === 8 ? '_blank' : '_self'} style={{ cursor: index === 8 ? 'pointer' : 'default' }}>
                      <div className="frame-dresscode">
                        <div className="frame">
                          <img src={frame} alt={frame} className="frame"></img>
                          <img src={imageUrl.file(value.foto)} alt={imageUrl.file(value.foto)} className="img"></img>
                        </div>
                        <div className="wrapper-day">
                          <div className="date">{value.caption.split('\n')[0]}</div>
                          <div className="info">{value.caption.split('\n').length > 1 ? value.caption.split('\n')[1].replace('&amp;', '&') : ''}</div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </>}
          </div>
        </div>
      </section>
    </>
  );
}

import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Materi } from "./pages/Materi";
import { Profile } from "./pages/Profile";
import { Reimburse } from "./pages/Reimburse";
import ScrollTop from "./helpers/ScrollTop";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import { SeattingArrangement } from "./pages/SeattingArrangement";
function App() {
  return (
  <Router>
      <ScrollTop></ScrollTop>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/profile/:slugId" element={<Profile />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/undangan/:slugId" element={<Profile />}></Route>
        <Route path="/materi" element={<Materi />}></Route>
        <Route path="/reimburse" element={<Reimburse />}></Route>
        <Route path="/seating-arrangement" element={<SeattingArrangement />}></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;

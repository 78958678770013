import React from "react";
import frameVideo from "../../assets/image/frame-video.png";
import hotel1 from "../../assets/image/info-hotel-1.png";
import hotel2 from "../../assets/image/info-hotel-2.png";
import transportasi from "../../assets/image/info-transportasi.png";
import raker from "../../assets/image/info-raker.png";
import msa from "../../assets/image/info-msa.png";
import tambahan from "../../assets/image/info-tambahan.png";
import YoutubeEmbed from "./YoutubeEmbed";

export function InfoPeserta({ onLoad, event }) {
  const extractYouTubeId = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : null;
  }

  return (
    <>
      <section id="info-peserta">
        <div className="container">
          {onLoad || extractYouTubeId(event.video) === null ? '' : <>
            <div className="frame-video">
              <img src={frameVideo} alt={frameVideo} className="frame"></img>
              <YoutubeEmbed embedId={extractYouTubeId(event.video)} />
            </div>
          </>}
          <div className="wrapper-info-peserta" id="information-participant">
            <div className="title text-center">Call Center Panitia</div>
            <div className="d-flex justify-content-center flex-wrap mt-5">
              <div className="text-center item-info">
                <a href="https://wa.me/6289648026279" target="_blank"><img src={transportasi} alt={transportasi} className=""></img></a>
              </div>
              <div className="text-center  item-info">
              <a href="https://wa.me/6282276682313" target="_blank"><img src={msa} alt={msa}></img></a>
              </div>
              <div className="text-center  item-info">
              <a href="https://wa.me/6281364899325" target="_blank"><img src={raker} alt={raker}></img></a>
              </div>
            </div>
            <div className="d-flex justify-content-center flex-wrap">
              <div className=" text-center  item-info">
              <a href="https://wa.me/6281298965180" target="_blank"><img src={hotel1} alt={hotel1}></img></a>
              </div>
              <div className=" text-center  item-info">
              <a href="https://wa.me/6285782662897" target="_blank"><img src={hotel2} alt={hotel2}></img></a>
              </div>
            </div>
            <div className="d-flex justify-content-center flex-wrap">
              <div className=" text-center  item-info">
                <img src={tambahan} alt={tambahan} className="tambahan"></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

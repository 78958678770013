import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import imglocation from "../../assets/image/location.png";
import imglocationMobile from "../../assets/image/location-mobile.png";

export function Maps({event}) {
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });
  return (
    <>
      <section id="location">
        <div className="container">
          <Link to={`https://maps.google.com/?q=${event.koordinat}`} target="_blank">
            {isPhoneScreen && (
              <img
                src={imglocationMobile}
                alt={imglocationMobile}
                className="img w-100"></img>
            )}
            {isDesktopScreen && (
              <img src={imglocation} alt={imglocation} className="img w-100"></img>
            )}
          </Link>
        </div>
      </section>
    </>
  );
}

import axios from "axios"
import Cookies from "universal-cookie"


const cksClient = () => {
    var cookies = new Cookies()

    return cookies
}

const apiClient = (withBearer = true) => {
    var _url = process.env.REACT_APP_API_URL
    const _tkn = cksClient().get('_authToken')
    const options = {
        baseURL: _url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        timeout: 600000,
    }

    if (withBearer) {
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${_tkn}`
        }
    }
    
    const client = axios.create(options)
    client.interceptors.request.use(
        requestConfig => requestConfig,
        requestError => {
            return Promise.reject(requestError)
        }
    )

    client.interceptors.response.use(
        response => Promise.resolve(response),
        error => {
            if (error.response) {
                const {status} = error.response
                if (status === 403) {
                    console.log('Global api check - Forbidden Access')
                }
            }

            return Promise.reject(error)
        }
    )

    return client
}

const formatMoney = (a) => {
    if (!a) {
        return '0';
    }

    return a.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const showCaption = (caption) => {
    if (caption) {
      var split = caption.toString().replace('\r', '').split('\n');
      var text = split[0];
      if (split.length == 2) {
        
      }
      for (const key in split) {
        if (Object.hasOwnProperty.call(split, key)) {
            const element = split[key];
            if (key > 0) {
                text += `\n${element}`    
            }
        }
      }
      return text;
    }
    return '';
  }

export {
    apiClient, cksClient,formatMoney, showCaption
}
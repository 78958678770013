import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollTop = () => {
  // Extracts pathname property(key) from an object
  const history = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    setTimeout(() => {
      if (history.hash) {
        const element = document.getElementById(history.hash.replace("#", ""));
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo(0, 0);
      }
    }, 200);
    // console.log(history.hash);
  }, [history]);
};

export default ScrollTop;

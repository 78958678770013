var _url = process.env.REACT_APP_DATA_URL;
var _api = process.env.REACT_APP_API_URL;

const imageUrl = {
    file: (string) => {
        return `${_url}/files/${string}?not-from-cache-please`
    },
    qrcode: (string) => {
        return `${_url}/qr_code/${string}?not-from-cache-please`
    },
    thumbnailVideo: (string) => {
        return `${_api}/get-thumbnial-video/${string}`;
    }
}


export {
    imageUrl
}
import React from "react";
import img from "../../assets/image/img-about.jpg";
import frame from "../../assets/image/frame-about.png";
import Skeleton from "react-loading-skeleton";

export function About({content, onLoad}) {
  
  return (
    <>
      <section id="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-4 d-flex justify-content-center">
              <div className="frame-about">
                <img src={img} alt={img} className="img"></img>
                <img src={frame} alt={frame} className="frame"></img>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="title">
                TENTANG<br></br>RAKER R<span>K</span>AP 2024
              </div>
              {onLoad ?
              <div className="desc">
                <Skeleton count={5} className="d-inline-block mb-2" width={'100%'}></Skeleton>
            </div>
              : <div className="desc" dangerouslySetInnerHTML={{ __html: content }}>
            </div>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";
import playIcon from "../../assets/image/play-icon.svg"

export function Galery({ onLoad, list }) {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });
  const [listImg, setListImg] = useState([]);
  const [listType, setListType] = useState([]);

  function openLightboxOnSlide(number) {
    console.log(number);
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    var item = [];
    var type = []
    for (const key in list) {
      if (Object.hasOwnProperty.call(list, key)) {
        const element = list[key];
        for (const i in element) {
          if (Object.hasOwnProperty.call(element, i)) {
            const k = element[i];
            item.push(imageUrl.file(k.foto));
            type.push(checkExtenstion(k.foto.toString()))
          }
        }
      }
    }
    setListImg(item);
    setListType(type);
  }, [list])

  const checkExtenstion = (foto) => {
    var split = foto.toString().split('.');
    if (split[split.length - 1] == 'mp4' || split[split.length - 1] == 'MP4') {
      return 'video';
    }
    return 'image';
  }

  return (
    <>
      <section id="galery">
        <div className="container">
          <div className="title">
            GALERI<br></br>
            <div className="ps-0 ps-md-5 mt-2">
              FOTO RAKER R<span>K</span>AP 2024
            </div>
            {/* <div className="ps-0 ps-md-5 mt-1 text-updated" style={{ fontSize: '16px' }}>will be updated</div> */}
          </div>
          <div className="mt-5">
            {onLoad ?
              <>
                <div className="row align-items-center mt-5">
                  {['', '', '', ''].map((value, index) => (
                    <div className="col-6 col-md-3" key={`skeleton-galeri${index}`}>
                      <div className="frame-wrap" style={{ filter: 'unset', overflow: 'unset' }}>
                        <Skeleton width={'100%'} height={'100%'}></Skeleton>
                      </div>
                    </div>
                  ))}
                </div>
              </>
              : <>
                <Carousel
                  responsive={responsive}
                  arrows={true}
                  draggable
                  infinite
                  focusOnSelect={false}>
                  {list.map((galeri, index) => (
                    <div className="row align-items-center mt-5" key={`galeri-${index}`}>
                      {galeri.map((item, i) => (
                        <div className="col-6 col-md-3" key={`galerifile${index}-${i}`}>
                          <div className="frame-wrap">
                            <img
                              onClick={() => openLightboxOnSlide((index * 8) + i + 1)}
                              src={checkExtenstion(item.foto) == 'image' ? imageUrl.file(item.foto) : imageUrl.thumbnailVideo(item.foto)}
                              alt={item.foto}
                              className="frame"></img>
                            {checkExtenstion(item.foto) == 'video' ? <>
                            <img src={playIcon} alt="play-icon" className="frame-play"></img>
                            </>
                              : ''}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </Carousel>

                <FsLightbox
                  toggler={lightboxController.toggler}
                  sources={listImg}
                  types={listType}
                  slide={lightboxController.slide}
                />
              </>}

          </div>
        </div>
      </section>
    </>
  );
}

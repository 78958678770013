import React from "react";
import frame from "../../assets/image/frame-agenda.png";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";

export function AgendaDay1({ onLoad, dayOne, dayTwo, speakers }) {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 1,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 1,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };
  return (
    <>
      <section id="day1" className="agenda">
        <div className="container">
          <div className="title text-center">Overview agenda</div>
          <div className="title mt-5 main-title text-center">
            Day 1, 14 des 2023
          </div>
          <div className="row mt-5 justify-content-center">
            {onLoad ? <>
              {['', ''].map((v, i) => (
                <div className="col-12 col-md-4" key={`skeleton-day1${i}`}>
                  <div className="frame-agenda">
                    <Skeleton width={'100%'} height={'100%'}></Skeleton>
                  </div>
                </div>
              ))}
            </>
              : <>
                {dayOne.map((value, index) => (
                  <div className="col-12 col-md-4" key={`dayone${index}`}>
                    <div className="frame-agenda">
                      <img src={imageUrl.file(value.foto)} alt={imageUrl.file(value.foto)} className="img"></img>
                      <img src={frame} alt={frame} className="frame"></img>
                      <div className="text">{value.caption.split('\n')[0]}<br />{value.caption.split('\n').length > 1 ? value.caption.split('\n')[1].replace('&amp;', '&') : ''}</div>
                    </div>
                  </div>
                ))}
              </>}
          </div>
          <div className="title mt-5 main-title text-center">
            Day 2, 15 des 2023
          </div>
          <div className="row mt-5 justify-content-center">
            {onLoad ? <>
              {['', ''].map((v, i) => (
                <div className="col-12 col-md-4" key={`skeleton-day2${i}`}>
                  <div className="frame-agenda">
                    <Skeleton width={'100%'} height={'100%'}></Skeleton>
                  </div>
                </div>
              ))}
            </>
              : <>
                {dayTwo.map((value, index) => (
                  <div className="col-12 col-md-4" key={`daytwo${index}`}>
                    <div className="frame-agenda">
                      <img src={imageUrl.file(value.foto)} alt={imageUrl.file(value.foto)} className="img"></img>
                      <img src={frame} alt={frame} className="frame"></img>
                      <div className="text">{value.caption.split('\n')[0]}<br />{value.caption.split('\n').length > 1 ? value.caption.split('\n')[1].replace('&amp;', '&') : ''}</div>
                    </div>
                  </div>
                ))}
              </>}
          </div>

          <div className="title mt-5 text-center main-title">SPEAKERS</div>
          <div className="row justify-content-center mt-5 speakers">
            {onLoad ? <>
              {['', '', ''].map((v, i) => (
                <div className="col-12 col-md-4" key={`skeleton-speakers${i}`}>
                  <div className="frame-agenda">
                    <Skeleton width={'100%'} height={'100%'}></Skeleton>
                  </div>
                </div>
              ))}
            </>
              : <>
                {speakers.map((value, index) => (
                  <div className="col-12 col-md-4" key={`speakers${index}`}>
                    <div className="frame-dresscode">
                      <div className="frame">
                        <img src={imageUrl.file(value.foto)} alt={imageUrl.file(value.foto)} className="img"></img>
                        <img src={frame} alt={frame} className="frame"></img>
                      </div>
                      <div className="wrapper-day">
                        <div className="date">{value.caption.split('\n')[0]}</div>
                        <div className="info">{value.caption.split('\n').length > 1 ? value.caption.split('\n')[1].replace('&amp;', '&') : ''}</div>
                      </div>
                      {/* <div className="text">{value.caption.split('\n')[0]}<br/>{value.caption.split('\n').length > 1 ? value.caption.split('\n')[1].replace('&amp;', '&') : ''}</div> */}
                    </div>
                  </div>
                ))}
              </>}
          </div>
        </div>
      </section>
    </>
  );
}

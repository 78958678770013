import React, { useEffect, useRef, useState } from "react";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import { get_DataMateri } from "../actions";
import bgcenter from "../../src/assets/image/bg-full-2.png";
import imageArrangement from "../assets/image/meja_msa.png";
import FsLightbox from "fslightbox-react";

import Skeleton from "react-loading-skeleton";
import dataJson from '../assets/json/meja_msa.json'
export function SeattingArrangement() {
  const myElementRef = useRef(null);
  const [toggler, setToggler] = useState(false);

  const [onLoad, setLoad] = useState(true);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [maxPage, setMaxPage] = useState(0)

  const getData = () => {
    setLoad(true);
    get_DataMateri({}, (res) => {
      setLoad(false);
      setList(res.list);
    });
  };

  useEffect(() => {
    getMaxPage();
    // getData();
  }, []);

  const getMaxPage = () => {
    var maxPage = Math.ceil(dataJson.length / 20)
    if (keyword.trim() != "" && keyword.length > 0) {
      var filter = dataJson.filter((e) => e.nama.toLowerCase().includes(keyword.toLowerCase()) || e.jabatan.toLowerCase().includes(keyword.toLowerCase()) || e.nomor.toLowerCase().includes(keyword.toLowerCase()))
      maxPage = Math.ceil(filter.length / 20)
    }
    setMaxPage(maxPage);
  }

  const setPaging =  () => {
    const myElement = myElementRef.current;
    var start = (page - 1) * 20;
    var end = page * 20;
    var obj = dataJson.slice(start, end);
    if (keyword.trim() !== "" && keyword.length > 0) {
      var filter = dataJson.filter((e) => e.nama.toLowerCase().includes(keyword.toLowerCase()) || e.jabatan.toLowerCase().includes(keyword.toLowerCase()) || e.nomor.toLowerCase().includes(keyword.toLowerCase()))
      console.log(filter);
      obj = filter.slice(start, end)
    }
    setList(obj);
    if (myElement) {
      const offsetTop = myElement.offsetTop;
      myElement.scrollIntoView({ top: offsetTop, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    setPaging();
  }, [page]);

  useEffect(() => {
    getMaxPage();
    setPage(1);
    setPaging();
  }, [keyword]);
  return (
    <>
      <Nav></Nav>
      <section id="profile">
        <img src={bgcenter} alt={bgcenter} className="bg-profile"></img>
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="title seating">Seatting Arrangement</div>
          </div>
          <div className="wrapper">
            <div className="row mb-5 justify-content-center">
              <div className="col-12 col-md-8">
                <div className="text-center">
                  <img
                    onClick={() => setToggler(!toggler)}
                    src={imageArrangement}
                    alt={imageArrangement}
                    className="pointer img-seating"
                    style={{ width: "100%" }}></img>
                </div>
              </div>
              <div className="col-12 col-md-8 mt-3">
                <div className="wrapper-upload wrapper-table" ref={myElementRef}>
                  <div className="title-desc text-center table" style={{ lineHeight: '30px' }}>
                    Daftar Peserta <br /> dan <br /> Nomor Tabel
                  </div>
                  <div className="desc">
                    <div class="float-end">
                      <input type="text" class="form-control" placeholder="Ketik nama" onKeyUp={(e) => {
                        setKeyword(e.target.value)
                        // setPage(0)
                        // setTimeout(() => {
                        //   setPage(1)
                        // }, 10);
                      }} />
                    </div>
                    <div className="clearfix">
                      <hr />
                    </div>
                    {list.map((value, index) => (
                      <>
                        <div className="d-flex justify-content-between" key={`tabel${index}`}>
                          <div className="text-left header">{value.nama}</div>
                          <div className="text header text-jabatan">{`${value['jabatan']}`}</div>
                          <div className="text header">{`${value['nomor']}`}</div>
                        </div>
                        <hr></hr>
                      </>
                    ))}
                    <div className="d-flex justify-content-center mt-3">
                      {page != 1 ? <><button className="btn btn-pagination me-3" onClick={() => setPage(page - 1)}>Prev</button> </> : ''}
                      {page != maxPage ? <><button className="btn btn-pagination" onClick={() => setPage(page + 1)}>Next</button> </> : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FsLightbox toggler={toggler} sources={[imageArrangement]} />

            {/* {onLoad ? 
            <>
            {['', '', '', ''].map((value, index) => (
              <div className="mb-2" key={index}>
                <Skeleton width={'100%'} borderRadius={10} height={80}></Skeleton>
              </div>
            ))}
            </>
            : 
            <>
            {list.length > 0 ?list.map((value, index) => {
              return (<>
                <div className="d-flex justify-content-between item-file align-items-center" key={index}>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src={getFileIcon(value.file)} alt={getFileIcon(value.file)} className="icon-file"></img>
                    </div>
                    <div className="text">{value.nama}</div>
                  </div>
                  <div className="">
                    <a href={imageUrl.file(value.file)} target="_blank"><img src={iconDownload} alt={iconDownload} className=""></img></a>
                  </div>
                </div>
              </>);
            }) : 
            <>
            <div className="text-center text-willbeupdate">
                WILL BE UPDATE
            </div>
            </>
            
            }
            </>} */}
          </div>
        </div>
        <Footer></Footer>
      </section>
    </>
  );
}

import { apiClient } from "../helpers"

const _URL = {
    home: '/home',
    materi: '/file',
    checkInvite: '/check-invite/:slugId',
    confirmation: '/confirmation',
    uploadFile: '/upload-file',
}


const get_CheckInvite = (slugId, successCallback, errorCallback) => {
    apiClient(false).get(_URL.checkInvite.replace(':slugId', slugId)).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const post_Confirmation = (params, successCallback, errorCallback) => {
    apiClient(true).post(_URL.confirmation, params).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_DataHome = (params, successCallback, errorCallback) => {
    apiClient(true).get(_URL.home, params).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const get_DataMateri = (params, successCallback, errorCallback) => {
    apiClient(true).get(_URL.materi, params).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

const post_UploadFile = (params, successCallback, errorCallback) => {
    apiClient(true).post(_URL.uploadFile, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      }).then((result) => {
        if (result.status === 200 && typeof successCallback == "function") {
            return successCallback(result.data)
        }
    }).catch(err => {
        if (errorCallback && typeof errorCallback == "function") {
            errorCallback(err.response.data)
        }
    })
}

export {
    get_DataHome,
    get_DataMateri,
    get_CheckInvite,
    post_UploadFile,
    post_Confirmation
}
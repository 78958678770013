import React, { useState, useRef, useEffect } from "react";
import { Footer } from "../components/Footer";
import bg from "../assets/image/bg-full-2.png";
import imgUpload from "../assets/image/img-upload.png";
import btnUpload from "../assets/image/btn-upload-file.png";
import { Nav } from "../components/Nav";
import { cksClient } from "../helpers";
import warningIcon from "../assets/image/info-circle.svg";
import checklistIcon from "../assets/image/tick-circle.svg";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { get_CheckInvite, post_UploadFile } from "../actions";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";

export function Reimburse() {
  const [onLoad, setLoad] = useState(true);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const typeReimburse = cksClient().get("_rimtp");
  const uniqCode = cksClient().get("_unq");
  const hiddenFileInput = useRef(null);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [dataPeserta, setPeserta] = useState({
    id: "",
    nama: "",
    gender: "",
    jabatan: "",
    nip: "",
    email: "",
    bus: "",
    nama_hotel: "",
    raker_nomor: "",
    msa_nomor: "",
    kode_unik: "",
    status: "",
    show_kamar: false,
  });

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      var maxSize = 10 * 1024 * 1024;
      if (file.size <= maxSize) {
        var formData = new FormData();

        formData.append("kategori", typeReimburse);
        formData.append("file", file);
        formData.append("uuid", dataPeserta.id);

        MySwal.fire({
          html: `
          <div class="text">
            <b>Tunggu Sebentar...</b>
          </div>`,
          confirmButtonText: "Tutup",
          background: "#0E2C4E",
          color: "#fff",
          showConfirmButton: false,
          allowOutsideClick: false,
        });
        MySwal.showLoading();

        post_UploadFile(
          formData,
          (res) => {
            MySwal.hideLoading();
            MySwal.close();

            MySwal.fire({
              html: `
            <div><img src="${checklistIcon}" style="width: 120px;"></img></div>
            <div class="mt-3 text">
              <b>File Reimburse berhasil di simpan!</b>
            </div>`,
              confirmButtonText: "Tutup",
              background: "#0E2C4E",
              color: "#fff",
              padding: "2em",
              confirmButtonColor: "#26B2C3",
              customClass: {
                confirmButton: "mt-4 button-alert",
              },
            }).then(() => {
              navigate(`/`);
            });
          },
          () => {
            MySwal.hideLoading();
            MySwal.close();
            MySwal.fire({
              html: `
            <div><img src="${warningIcon}" style="width: 120px;"></img></div>
            <div class="mt-3 text">
              <b>Upload file tidak berhasil, silakan coba beberpa saat lagi</b>
            </div>`,
              confirmButtonText: "Scan",
              background: "#0E2C4E",
              color: "#fff",
              padding: "2em",
              confirmButtonColor: "#26B2C3",
              customClass: {
                confirmButton: "mt-4 button-alert",
              },
            });
          }
        );
      } else {
        hiddenFileInput.current.value = "";
        MySwal.fire({
          html: `
          <div><img src="${warningIcon}" style="width: 120px;"></img></div>
          <div class="mt-3 text">
            <b>Ukuran file maximal 10MB</b>
          </div>`,
          confirmButtonText: "Scan",
          background: "#0E2C4E",
          color: "#fff",
          padding: "2em",
          confirmButtonColor: "#26B2C3",
          customClass: {
            confirmButton: "mt-4 button-alert",
          },
        });
      }
    }
  };

  const getProfile = () => {
    setLoad(true);
    get_CheckInvite(
      uniqCode,
      (res) => {
        setLoad(false);
        const { peserta } = res;
        setPeserta(peserta);
      },
      () => {
        if (MySwal.getPopup() == null) {
          MySwal.fire({
            html: `
        <div><img src="${warningIcon}" style="width: 120px;"></img></div>
        <div class="mt-3 text">
          <b>Data Tidak Ditemukan</b>
        </div>`,
            confirmButtonText: "Tutup",
            background: "#0E2C4E",
            color: "#fff",
            padding: "2em",
            confirmButtonColor: "#26B2C3",
            customClass: {
              confirmButton: "mt-4 button-alert",
            },
          }).then(() => {
            navigate(`/`);
          });
        }
      }
    );
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Nav></Nav>
      <section id="profile">
        <img src={bg} alt={bg} className="bg-profile"></img>
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="title">REIMBURSE</div>
          </div>

          <div className="row wrapper">
            <div className="col-12 col-md-8 mb-3">
              <div className="d-flex mb-3 item">
                <div className="text-left">QR CODE</div>
                <div className="text">
                  {onLoad ? (
                    <Skeleton width={400} height={40}></Skeleton>
                  ) : (
                    dataPeserta.kode_unik
                  )}
                </div>
              </div>
              <div className="d-flex mb-3 item">
                <div className="text-left">NAMA</div>
                <div className="text">
                  {onLoad ? (
                    <Skeleton width={400} height={40}></Skeleton>
                  ) : (
                    dataPeserta.nama
                  )}
                </div>
              </div>
              <div className="d-flex mb-3 item">
                <div className="text-left">JABATAN</div>
                <div className="text">
                  {onLoad ? (
                    <Skeleton width={400} height={40}></Skeleton>
                  ) : (
                    dataPeserta.jabatan
                  )}
                </div>
              </div>
              {dataPeserta.show_kamar ?
                <>
                  <div className="d-flex mb-3 item">
                    <div className="text-left">HOTEL</div>
                    <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.nama_hotel}</div>
                  </div>
                  <div className="d-flex mb-3 item">
                    <div className="text-left">TIPE KAMAR</div>
                    <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.nama_kamar}</div>
                  </div>
                  <div className="d-flex mb-3 item">
                    <div className="text-left">NOMOR KAMAR</div>
                    <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.nomor_kamar}</div>
                  </div>
                </> : ''}
              {/* <div className="d-flex mb-3 item">
                <div className="text-left">HOTEL</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.nama_hotel }</div>
              </div> */}
              <div className="d-flex mb-3 item">
                <div className="text-left">MEJA REGISTRASI <br /> KEDATANGAN</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : (dataPeserta.registrasi_number ?? '-')}</div>
              </div>
              <div className="d-flex mb-3 item">
                <div className="text-left">MEJA RAKER</div>
                <div className="text">
                  {onLoad ? (
                    <Skeleton width={400} height={40}></Skeleton>
                  ) : (
                    dataPeserta.raker_nomor && dataPeserta.raker_nomor != "-" ? 'Tabel '+ dataPeserta.raker_nomor + ' - No.'+ dataPeserta.nomor_meja_raker :  "-"
                  )}
                </div>
              </div>
              <div className="d-flex mb-3 item">
                <div className="text-left">NOMOR BUS <br></br> MALIOBORO TOUR</div>
                <div className="text">
                  {onLoad ? (
                    <Skeleton width={400} height={40}></Skeleton>
                  ) : (
                    dataPeserta.bus ?? "-"
                  )}
                </div>
              </div>
              <div className="d-flex mb-3 item">
                <div className="text-left">MEJA MSA</div>
                <div className="text">
                  {onLoad ? (
                    <Skeleton width={400} height={40}></Skeleton>
                  ) : (
                    dataPeserta.msa_nomor ?? "-"
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-3">
              {/* <div className="text mb-4 font-weight-600">
                UPLOAD FILE <br></br> REIMBURSE{" "}
                {typeReimburse.toString().toUpperCase()}
              </div> */}
              <div className="wrapper-upload" >
                <div className="title-desc">Informasi</div>
                <div className="desc">
                  {typeReimburse === "spd" ? (
                    <>
                      <p>
                        1. Kepada Bapak/Ibu Peserta untuk dapat menyerahkan SPD
                        (Surat Perjalanan Dinas) untuk kami stample dan
                        mengupload SPD, Boarding Pass Pesawat / Tiket Kereta
                        keberangkatan pada saat registrasi kedatangan (Hotel)
                        dalam rangka pemenuhan administrasi uang saku.
                      </p>
                      <p>
                        2. Selanjutnya mohon dapat dipastikan agar masing-masing
                        peserta Raker dan MSA tidak melakukan klaim uang saku
                        dan uang makan di tanggal 14-17 Desember 2023.
                      </p>
                      <p>
                        3. Apabila terdapat Peserta yang masih dalam perjalanan
                        di luar tanggal tersebut (dikarenakan transit pesawat),
                        maka dapat diajukan SPD kepada panitia sesuai dengan
                        ketentuan perdin (khusus atas perjalanan diluar tgl
                        14-17 Des 2023) dan wajib menginformasikan kepada
                        panitia.
                      </p>
                      <p>
                        4. Apabila ada yg belum jelas dan ingin ditanyakan
                        terkait hal ini dapat menghubungi sdri. Veronica Jessy
                        no WA <a href="https://wa.me/6281286440613" target="_blank" style={{ textDecoration: 'none' }}>081286440613</a>.
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        1. Khusus untuk biaya transportasi kedatangan dan
                        kepulangan rumah - bandara - rumah (maks.300 rb) agar
                        dilakukan reimbursement ke Panitia maksimal H+5 setelah
                        Acara Raker dan MSA berlangsung. Dokumen reimbursement
                        untuk peserta dari KC dan KCP mohon agar di pooling di
                        Admin DH (Up. Veronica Jessy).
                      </p>
                      <p>
                        2. Peserta wajib untuk mengumpulkan dokumen
                        reimbursement maksimal 5 Hari Kalender setelah acara
                        Raker & MSA berakhir. Apabila dokumen diterima panitia
                        setelah tanggal yang ditentukan, maka seluruh biaya yang
                        timbul tidak dapat dilakukan reimbursement dan menjadi
                        beban masing-masing pegawai.
                      </p>
                      <p>
                        3. Apabila ada yg belum jelas dan ingin ditanyakan
                        terkait hal ini dapat menghubungi sdri. Veronica Jessy
                        no WA <a href="https://wa.me/6281286440613" target="_blank" style={{ textDecoration: 'none' }}>081286440613</a>.
                      </p>
                    </>
                  )}
                </div>
                <input
                  id="file"
                  type="file"
                  hidden
                  onChange={handleChange}
                  ref={hiddenFileInput}
                />
                {/* <img src={imgUpload} alt={imgUpload} className=""></img> */}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center my-5">
            <button className="btn-confirm" onClick={handleClick}>
              <img src={btnUpload} alt={btnUpload} className=""></img>
            </button>
          </div>
        </div>
        <Footer></Footer>
      </section>
    </>
  );
}

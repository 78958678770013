import React, { useState } from "react";
import imgfooter from "../../src/assets/image/img-footer.png";
import logofooter from "../../src/assets/image/logo-footer.png";
import leftlight from "../../src/assets/image/cahaya-kiri.svg";
import rightlight from "../../src/assets/image/cahaya-kanan.svg";
import frontlight from "../../src/assets/image/cahaya-depan.svg";
import kotalight from "../../src/assets/image/cahaya-kota.svg";
import tugulight from "../../src/assets/image/cahaya-tugu.svg";

export function Footer() {
  return (
    <>
      <footer>
          <img src={imgfooter} alt={imgfooter} className="img-footer"></img>
          <img src={logofooter} alt={logofooter} className="logo-footer"></img>
          <img src={leftlight} alt={leftlight} className="light-left"></img>
          <img src={frontlight} alt={frontlight} className="front-light"></img>
          <img src={kotalight} alt={kotalight} className="kota-light"></img>
          <img src={tugulight} alt={tugulight} className="tugu-light"></img>
          <img src={rightlight} alt={rightlight} className="right-light"></img>
      </footer>
    </>
  );
}

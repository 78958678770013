import React from "react";
import frame from "../../assets/image/frame-agenda.png";
import imgMsa from "../../assets/image/img-msa.jpg";
import filterInstagram from "../../assets/image/filter_instagram.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";

export function AgendaDay2({ onLoad, dayThree, listMsa }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section id="day1" className="agenda">
        <div className="container">
          <div className="title mt-5 main-title text-center">
            Day 3, 16 des 2023
          </div>
          <div className="row mt-5">
            {onLoad ? (
              <>
                {["", "", ""].map((v, i) => (
                  <div className="col-12 col-md-4" key={`skeleton-day3${i}`}>
                    <div className="frame-agenda">
                      <Skeleton width={"100%"} height={"100%"}></Skeleton>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {dayThree.map((value, index) => (
                  <div className="col-12 col-md-4" key={`day3${index}`}>
                    <div className="frame-agenda">
                      <img
                        src={imageUrl.file(value.foto)}
                        alt={imageUrl.file(value.foto)}
                        className="img"></img>
                      <img src={frame} alt={frame} className="frame"></img>
                      <div className="text">
                        {value.caption.split("\n")[0]}
                        <br />
                        {value.caption.split("\n").length > 1
                          ? value.caption.split("\n")[1].replace("&amp;", "&")
                          : ""}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="title mt-5 main-title text-center">MSA</div>
        </div>
        <div className="msa container">
          {onLoad ? (
            <>
              <Skeleton width={"100%"} height={"500px"}></Skeleton>
              {/* <div className="row align-items-center mt-5">
                {["", "", ""].map((v, i) => (
                  <div className="col-12 col-md-4" key={`skeleton-msa${i}`}>
                    <div className="frame-agenda">
                      <Skeleton width={"100%"} height={"100%"}></Skeleton>
                    </div>
                  </div>
                ))}
              </div> */}
            </>
          ) : (
            <>
              <div className="text-center mt-5">
                <img src={imgMsa} alt={imgMsa} className="img-msa"></img>
              </div>
              <div className="text-center mt-4">
                <img src={filterInstagram} alt={filterInstagram} className="img-msa"></img>
              </div>
              {/* <Carousel
                responsive={responsive}
                arrows={true}
                draggable
                infinite
                focusOnSelect={false}>
                {listMsa.map((value, index) => (
                  <div className="frame-agenda" key={`msa${index}`}>
                    <img src={imageUrl.file(value.foto)} alt={imageUrl.file(value.foto)} className="img"></img>
                    <img src={frame} alt={frame} className="frame"></img>
                    <div className="text">{value.caption.split('\n')[0]}<br />{value.caption.split('\n').length > 1 ? value.caption.split('\n')[1].replace('&amp;', '&') : ''}</div>
                  </div>
                ))}
              </Carousel> */}
            </>
          )}
        </div>
      </section>
    </>
  );
}

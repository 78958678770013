import React from "react";
import { useMediaQuery } from "react-responsive";
import hero from "../../assets/image/hero.png";
import heroMobile from "../../assets/image/hero-mobile.png";
import tagline from "../../assets/image/tagline.png";

export function Hero() {
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });

  return (
    <>
      <div id="hero">
        {isDesktopScreen && (
          <img src={hero} alt={hero} className="img-hero"></img>
        )}
        {isPhoneScreen && (
          <img src={heroMobile} alt={heroMobile} className="img-hero"></img>
        )}
        <img src={tagline} alt={tagline} className="tagline"></img>
       
      </div>
    </>
  );
}

import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../src/assets/image/logo-taspen.png";
import close from "../../src/assets/image/icon-close.svg";
import { Button } from "bootstrap";
import { cksClient } from "../helpers";

export function Nav() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [headerClassName, setHeaderClassName] = useState("");
  const [uniqCode, setUniqCode] = useState(cksClient().get('_unq', {path: '/'}) ?? '');
  const navigate = useNavigate();
  const {  pathname } = useLocation();

  const handleScroll = (headerClassName) => {
    if (headerClassName !== "menuscroll" && window.pageYOffset > 0) {
      setHeaderClassName("menuscroll");
    } else if (headerClassName === "menuscroll" && window.pageYOffset === 0) {
      setHeaderClassName("");
    }
  };
  const handleClickScroll = (target) => {
    const element = document.getElementById(target);
    if ( pathname === "/") {
      var headerOffset = 1005;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    } else {
      navigate(`/#${target}`);
    }
  };
  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]);

  React.useEffect(() => {
    setUniqCode(cksClient().get('_unq', {path: '/'}) ?? '');
  }, [pathname])

  return (
    <>
      <nav className={`navbar navbar-expand-lg ${headerClassName}  ${isNavExpanded ? "expanded" : ""}` } >
        <div className="w-100 py-2 container wrapper-nav">
          <div
            className={
              isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
            }>
            <div className="">
              <div className="navbar-nav mb-2 mb-lg-0 container">
                <li className="nav-item">
                  <Link to="/" className={`nav-link ${ pathname === '/' ? 'active' : '' }`}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <button
                    to=""
                    onClick={() => handleClickScroll("about")}
                    className="nav-link">
                    Tentang Acara
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    to=""
                    onClick={() => handleClickScroll("galery")}
                    className="nav-link">
                    Galeri
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    to=""
                    onClick={() => handleClickScroll("location")}
                    className="nav-link">
                    Peta Lokasi
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    to=""
                    onClick={() => handleClickScroll("rundown")}
                    className="nav-link">
                    Rundown
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    to=""
                    onClick={() => handleClickScroll("dresscode")}
                    className="nav-link">
                    Dress Code
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    to=""
                    onClick={() => handleClickScroll("information-participant")}
                    className="nav-link">
                    Call Center
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    to=""
                    onClick={() => handleClickScroll("reimburse")}
                    className="nav-link">
                    Reimburse
                  </button>
                </li>
                <li className="nav-item">
                  <Link to="/materi" className={`nav-link ${ pathname === '/materi' ? 'active' : '' }`}>
                    Materi
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/seating-arrangement" className={`nav-link ${ pathname === '/seating-arrangement' ? 'active' : '' }`}>
                    Seatting Arrangement
                  </Link>
                </li>
                {uniqCode ? <>
                  <li className="nav-item">
                  <Link to="/profile" className={`nav-link ${ pathname === '/profile' ? 'active' : '' }`}>
                    Profile
                  </Link>
                </li>
                </> : ''}
              </div>
            </div>
          </div>
          <Link
            variant="navbar-brand text-start d-flex align-items-center"
            to="/">
            <img src={logo} alt={logo} className="logo" />
          </Link>
        </div>
        <button
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          {isNavExpanded ? (
            <img src={close} alt={close}></img>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3 12H21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 6H21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 18H21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
      </nav>
    </>
  );
}

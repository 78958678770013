import React, { useEffect, useState } from "react";
import { Footer } from "../components/Footer";
import { Nav } from "../components/Nav";
import { get_DataMateri } from "../actions";
import { imageUrl } from "../actions/image";
import bgcenter from "../../src/assets/image/bg-full-2.png";
import iconDownload from "../assets/image/icon-download.png";
import iconFile from "../assets/image/icon-file.png";
import iconPdf from "../assets/image/img-pdf.png";
import iconPng from "../assets/image/img-png.png";
import iconPpt from "../assets/image/img-ppt.png";
import iconZip from "../assets/image/img-zip.png";

import Skeleton from "react-loading-skeleton";
export function Materi() {
  const [onLoad, setLoad] = useState(true);
  const [list, setList] = useState([]);

  const getData = () => {
    setLoad(true);
    get_DataMateri({}, (res) => {
      setLoad(false);
      setList(res.list);
      console.log(res)
    })
  }

  const getFileIcon = (name) => {
    var split = name.split('.');
    var ext = split[split.length - 1];
    if (ext.toString().toLowerCase() == 'pdf') {
      return iconPdf;
    } else if (ext.toString().toLowerCase() == 'png') {
      return iconPng;
    } else if (ext.toString().toLowerCase() == 'ppt') {
      return iconPpt;
    } else if (ext.toString().toLowerCase() == 'zip' || ext.toString().toLowerCase() == 'rar') {
      return iconPdf;
    } else {
      return iconFile;
    }
  }

  useEffect(() => {
    getData();   
  }, [])
  return (
    <>
      <Nav></Nav>
      <section id="profile">
        <img src={bgcenter} alt={bgcenter} className="bg-profile"></img>
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="title">Materi Kegiatan</div>
          </div>
          <div className="wrapper">
            {onLoad ? 
            <>
            {['', '', '', ''].map((value, index) => (
              <div className="mb-2" key={index}>
                <Skeleton width={'100%'} borderRadius={10} height={80}></Skeleton>
              </div>
            ))}
            </>
            : 
            <>
            {list.length > 0 ?list.map((value, index) => {
              return (<>
                <div className="d-flex justify-content-between item-file align-items-center" key={index}>
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src={getFileIcon(value.file)} alt={getFileIcon(value.file)} className="icon-file"></img>
                    </div>
                    <div className="text">{value.nama}</div>
                  </div>
                  <div className="">
                    <a href={imageUrl.file(value.file)} target="_blank"><img src={iconDownload} alt={iconDownload} className=""></img></a>
                  </div>
                </div>
              </>);
            }) : 
            <>
            <div className="text-center text-willbeupdate">
                WILL BE UPDATE
            </div>
            </>
            
            }
            </>}
          </div>
        </div>
        <Footer></Footer>
      </section>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Footer } from "../components/Footer";
import bgcenter from "../../src/assets/image/bg-full-2.png";
import buttonConfirm from "../assets/image/button-confirm.png";
import { Nav } from "../components/Nav";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Skeleton from "react-loading-skeleton";
import { get_CheckInvite, post_Confirmation } from "../actions";
import checklistIcon from '../assets/image/tick-circle.svg'
import warningIcon from '../assets/image/info-circle.svg'
import { imageUrl } from "../actions/image";
import { cksClient } from "../helpers";
import { Reimburse } from "../part/home/Reimburse";

export function Profile() {
  const [firstLoad, setFirst] = useState(true);
  const [onLoad, setLoad] = useState(true);
  const { pathname } = useLocation();
  const [dataPeserta, setPeserta] = useState({
    id: "",
    nama: '',
    gender: '',
    jabatan: '',
    nip: '',
    email: '',
    bus: '',
    nama_hotel: '',
    nama_kamar: '',
    nomor_kamar: '',
    raker_nomor: '',
    msa_nomor: '',
    kode_unik: '',
    status: '',
    show_kamar: false,
  });
  const { slugId } = useParams()
  const _unq = cksClient().get('_unq', { path: '/' });
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const getProfile = () => {
    setLoad(true)
    get_CheckInvite(slugId != "" && slugId != undefined ? slugId : _unq, (res) => {
      setLoad(false)
      const { peserta } = res
      cksClient().set('_unq', peserta.kode_unik, { expires: new Date(Date.now() + 2592000), path: '/' })
      setPeserta(peserta)
    }, () => {
      if (MySwal.getPopup() == null) {
        MySwal.fire({
          html: `
        <div><img src="${warningIcon}" style="width: 120px;"></img></div>
        <div class="mt-3 text">
          <b>Data Tidak Ditemukan</b>
        </div>`,
          confirmButtonText: "Tutup",
          background: "#0E2C4E",
          color: "#fff",
          padding: "2em",
          confirmButtonColor: "#26B2C3",
          customClass: {
            confirmButton: "mt-4 button-alert",
          },
        }).then((e) => {
          navigate(`/`);
        });
      }
    })
  }

  const confirmAction = (kode_unik) => {
    MySwal.fire({
      html: `
      <div class="text">
        <b>Tunggu Sebentar...</b>
      </div>`,
      confirmButtonText: "Tutup",
      background: "#0E2C4E",
      color: "#fff",
      showConfirmButton: false,
      allowOutsideClick: false
    });
    MySwal.showLoading();

    post_Confirmation({
      kode_unik: kode_unik
    }, (res) => {
      MySwal.hideLoading();
      MySwal.close();

      setPeserta({ ...dataPeserta, status: 'aktif' })

      MySwal.fire({
        html: `
        <div><img src="${checklistIcon}" style="width: 120px;"></img></div>
        <div class="mt-3 text">
          <b>Konfirmasi kehadiran anda berhasil!</b>
        </div>`,
        confirmButtonText: "Tutup",
        background: "#0E2C4E",
        color: "#fff",
        padding: "2em",
        confirmButtonColor: "#26B2C3",
        customClass: {
          confirmButton: "mt-4 button-alert",
        },
        allowOutsideClick: false,
      }).then((e) => {
        if (e.isConfirmed) {
          navigate(`/`);
        }
      });
    })
  }
  useEffect(() => {
    if (firstLoad) {
      setFirst(false);
      getProfile();
    }
  }, [])

  return (
    <>
      <Nav></Nav>
      <section id="profile">
        <img src={bgcenter} alt={bgcenter} className="bg-profile"></img>
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="title">PROFIL PESERTA</div>
          </div>

          <div className="row wrapper">
            <div className="col-12 col-md-3 order-2 order-md-1 mb-3">
              {onLoad ? <Skeleton width={'100%'} height={300}></Skeleton> : <img src={imageUrl.qrcode(dataPeserta.id + '.png')} alt={imageUrl.qrcode(dataPeserta.id + '.png')} className="qr-code"></img>}
              <div className="desc">
                {onLoad ? '' : 'Tunjukkan QR Code ini pada saat melakukan registrasi di tempat acara'}
              </div>
            </div>
            <div className="col-12 col-md-8 order-1 order-md-2 mb-3">
              <div className="d-flex mb-3 item">
                <div className="text-left">QR CODE</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.kode_unik}</div>
              </div>
              <div className="d-flex mb-3 item">
                <div className="text-left">NAMA</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.nama}</div>
              </div>
              <div className="d-flex mb-3 item">
                <div className="text-left">JABATAN</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.jabatan}</div>
              </div>
              {dataPeserta.show_kamar ?
                <>
                  <div className="d-flex mb-3 item">
                    <div className="text-left">HOTEL</div>
                    <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.nama_hotel}</div>
                  </div>
                  <div className="d-flex mb-3 item">
                    <div className="text-left">TIPE KAMAR</div>
                    <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.nama_kamar}</div>
                  </div>
                  <div className="d-flex mb-3 item">
                    <div className="text-left">NOMOR KAMAR</div>
                    <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.nomor_kamar}</div>
                  </div>
                </> : ''}
              {/* <div className="d-flex mb-3 item">
                <div className="text-left">HOTEL</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : dataPeserta.nama_hotel }</div>
              </div> */}
              <div className="d-flex mb-3 item">
                <div className="text-left">MEJA REGISTRASI <br /> KEDATANGAN</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : (dataPeserta.registrasi_number ?? '-')}</div>
              </div>
              <div className="d-flex mb-3 item">
                <div className="text-left">MEJA RAKER</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : (dataPeserta.raker_nomor && dataPeserta.raker_nomor != "-" ? 'Tabel '+  dataPeserta.raker_nomor + ' - No.'+ dataPeserta.nomor_meja_raker :  "-")}</div>
              </div>
              <div className="d-flex mb-3 item">
                <div className="text-left">NOMOR BUS <br></br> MALIOBORO TOUR</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : (dataPeserta.bus ?? '-')}</div>
              </div>

              <div className="d-flex mb-3 item">
                <div className="text-left">MEJA MSA</div>
                <div className="text">{onLoad ? <Skeleton width={400} height={40}></Skeleton> : (dataPeserta.msa_nomor ?? '-')}</div>
              </div>
            </div>
          </div>
              
          <Reimburse type="profile"></Reimburse>

          {dataPeserta.status === 'waiting' && pathname.indexOf('/undangan') >= 0 ? <><div className="d-flex justify-content-center mt-5">
            <button className="btn-confirm" onClick={() => confirmAction(dataPeserta.kode_unik)}>
              <img src={buttonConfirm} alt={buttonConfirm} className=""></img>
            </button>
          </div>
          </>
            : ''}
        </div>
        <Footer></Footer>
      </section>
    </>
  );
}

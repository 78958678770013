import React, { useState } from "react";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import frameDetail from "../../assets/image/frame-detail.png";
import frameDetailMobile from "../../assets/image/frame-detail-mobile.png";
import frameTime from "../../assets/image/frame-time.png";
import frameRundown from "../../assets/image/frame-rundown.png";
import Skeleton from "react-loading-skeleton";

import moment from 'moment';
import 'moment/locale/id';
export function Rundown({ onLoad, list }) {
  const [basicActive, setBasicActive] = useState("tabindex0");

  const handleBasicClick = (value) => {
    console.log(value);
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  return (
    <>
      <section id="rundown">
        <div className="container">
          <div className="title text-center">
            RUNDOWN<br></br>RAKER R<span>K</span>AP 2024
          </div>
        </div>

        <div className="">
          {onLoad ?
            <>
              <div className="container mt-4">
                <Skeleton count={3} className="d-inline-block mb-2" width={'100%'} height={100}></Skeleton>
              </div>
            </>
            : <>
              <div className="wrapper-tabs">
                <MDBTabs className="mb-5 mt-5 ">
                  {list.map((value, index) => (
                    <MDBTabsItem key={`headertab${index}`}>
                      <MDBTabsLink
                        onClick={() => handleBasicClick(`tabindex${index}`)}
                        active={basicActive === `tabindex${index}`}>
                        <div className="frame-tab-rundown">
                          <img src={frameRundown} alt={frameRundown}></img>
                          <div className="text">{moment(value.tanggal).format('DD MMM YYYY')}</div>
                        </div>
                      </MDBTabsLink>
                    </MDBTabsItem>
                  ))}
                </MDBTabs>
              </div>
              <div className="container">
                <MDBTabsContent>
                  {list.map((value, index) => (
                    <MDBTabsPane open={basicActive === `tabindex${index}`} key={`bodytab${index}`}>
                      {value.list.map((agenda, i) => (
                        <div className="item-rundown" key={`bodyitemtab${i}`}>
                          <div className="frame-detail">
                            <img
                              src={frameDetail}
                              alt={frameDetail}
                              className="frame desktop"></img>
                            <img
                              src={frameDetailMobile}
                              alt={frameDetailMobile}
                              className="frame frame-mobile"></img>
                            <div className="detail">
                              {agenda.keterangan}
                            </div>
                            <div className="time">{agenda.jam_mulai.toString().substr(0, 5)} {agenda.jam_selesai ? ' - '+agenda.jam_selesai.toString().substr(0, 5) : ''}</div>
                          </div>
                        </div>
                      ))}
                    </MDBTabsPane>
                  ))}
                </MDBTabsContent>
              </div>
            </>}
        </div>
      </section>
    </>
  );
}

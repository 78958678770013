import React from "react";
import frame from "../../assets/image/frame-agenda.png";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";
import { imageUrl } from "../../actions/image";
import imgEnabler1 from "../../assets/image/komite-enabler/img-1-enabler.png";
import imgEnabler2 from "../../assets/image/komite-enabler/img-2-enabler.png";
import imgEnabler3 from "../../assets/image/komite-enabler/img-3-enabler.png";
import imgEnabler4 from "../../assets/image/komite-enabler/img-4-enabler.png";
import imgEnabler5 from "../../assets/image/komite-enabler/img-5-enabler.png";
import imgEnabler6 from "../../assets/image/komite-enabler/img-6-enabler.png";

import imgBisnis1 from "../../assets/image/komite-bisnis/img-bisnis-1.png";
import imgBisnis2 from "../../assets/image/komite-bisnis/img-bisnis-2.png";
import imgBisnis3 from "../../assets/image/komite-bisnis/img-bisnis-3.png";
import imgBisnis4 from "../../assets/image/komite-bisnis/img-bisnis-4.png";
import imgBisnis5 from "../../assets/image/komite-bisnis/img-bisnis-5.png";
import imgBisnis6 from "../../assets/image/komite-bisnis/img-bisnis-6.png";

import imgModerator1 from "../../assets/image/komite-enabler/img-moderator-1.png";
import imgModerator2 from "../../assets/image/komite-enabler/img-moderator-2.png";

import imgModerator3 from "../../assets/image/komite-bisnis/img-moderator-1.png";
import imgModerator4 from "../../assets/image/komite-bisnis/img-moderator-2.png";

export function Komite({ onLoad, dayOne, dayTwo, speakers }) {
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 1,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 1,
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //   },
  // };
  return (
    <>
      <section id="day1" className="agenda">
        <div className="container">
          <div className="title mt-5 text-center main-title">
            Komite Business
          </div>
          <div className="tagline">
            "Maintaining Sustainability Business Growth <br></br> Trhu
            Leveraging Pension Ecosystem"
          </div>
          <div className="title-topic">topic goals</div>
          <div className="desc-goals">
            Bagaimana bisnis Bank Mandiri Taspen kedepan tidak hanya tumbuh
            sustain <br></br> melampaui target yang ditetapkan namun juga
            disertai dengan adanya <br></br> perbaikan kualitas portfolio
            lending dan funding.
          </div>
          <ul className="list-goals">
            <li>
              Optimalisasi atas intensifikasi nasabah funding eksisting dan
              ektensifikasi new potensial business funding melalui penyempurnaan
              database hot prospect funding, pembentukan ekosistem Pensiunan,
              serta inovasi program & produk funding
            </li>
            <li>
              Mendorong pertumbuhan kredit yang prudent dan sustain melalui
              optimalisasi akuisisi Pensiunan ASN & TNI/POLRI, perbaikan RAC
              produk lending eksisting, dan inovasi produk-produk baru.
            </li>
            <li>
              Leverage FBI recurring dengan pembuatan program peningkatan user
              pengguna e-channel, penyempurnaan fitur transaksi e-channel
              eksisting dan pengembangan QRIS Acquirer sebagai New FBI
              Generator.
            </li>
          </ul>
          <div className="sub-title">Team</div>
          <div className="row justify-content-center mt-5 speakers">
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img src={imgBisnis1} alt={imgBisnis1} className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">erlinda rusda</div>
                  <div className="info">
                    (distribution & portfolio management)
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img src={imgBisnis2} alt={imgBisnis2} className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">tambos hutabarat</div>
                  <div className="info">(pension & loyality management)</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img src={imgBisnis3} alt={imgBisnis3} className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">yuli clara</div>
                  <div className="info">(funding & investment product)</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img src={imgBisnis4} alt={imgBisnis4} className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">pangrukti kisbandini</div>
                  <div className="info">
                    (digital & strategy business development)
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img src={imgBisnis5} alt={imgBisnis5} className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">m. yusup</div>
                  <div className="info">(pension business)</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img src={imgBisnis6} alt={imgBisnis6} className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">m. razif</div>
                  <div className="info">(micro & retail business)</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-title"> moderator</div>
          <div className="row justify-content-center mt-5">
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgModerator3}
                    alt={imgModerator3}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day" style={{ bottom: "6%" }}>
                  <div className="date">aditya subiyanto</div>
                  <div className="info"></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgModerator4}
                    alt={imgModerator4}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day" style={{ bottom: "6%" }}>
                  <div className="date">tiara mayasari</div>
                  <div className="info"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="title mt-5 text-center main-title">
            Komite Enablers
          </div>
          <div className="tagline">
            "Accelerate Business Growth thru Empowering Talent, <br></br>{" "}
            Embracing Values of Mantap Pilar & Modernizing IT"
          </div>
          <div className="title-topic">topic goals</div>
          <div className="desc-goals">
            Bagaimana kedepan Branding, SDM, dan Infrastruktur IT dapat turut
            serta mendorong peningkatan kualitas <br></br> layanan dan
            operational excellence sehingga Bank Mandiri Taspen menjadi Top of
            Mind Pension Bank dan <br></br> mulai dikenal oleh masyarakat luas
          </div>
          <ul className="list-goals">
            <li>
              Memperkuat branding Bank Mandiri Taspen melalui peningkatan
              awareness Nasabah, leverage kerjasama branding dengan rekanan,
              optimalisasi program 3 pilar, peningkatkan loyalty nasabah, dan
              penguatan media engagement.
            </li>
            <li>
              Menciptakan Bankers Mantap yang kompeten & reliable melalui
              pengembangan performance management, people development dan
              organization culture.
            </li>
            <li>
              Mendorong pertumbuhan bisnis, perlindungan keamanan, penguatan
              operasional dan peningkatan customer experience dengan peningkatan
              Infrastruktur IT, e-Channel dan Implementasi New Core Banking
              System (NCBS).
            </li>
          </ul>
          <div className="sub-title">Team</div>
          <div className="row justify-content-center mt-5 speakers">
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgEnabler1}
                    alt={imgEnabler1}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">m. guntur</div>
                  <div className="info">(digital transformation)</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgEnabler2}
                    alt={imgEnabler2}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">oktaria ineztianty</div>
                  <div className="info">(human capital)</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgEnabler3}
                    alt={imgEnabler3}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">errinto pardede</div>
                  <div className="info">(corporate secretary & legal)</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgEnabler4}
                    alt={imgEnabler4}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">purnama sulfa</div>
                  <div className="info">(ciso)</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgEnabler5}
                    alt={imgEnabler5}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">hendi hadianto</div>
                  <div className="info">(it planning & development)</div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgEnabler6}
                    alt={imgEnabler6}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day">
                  <div className="date">fajar sukmono</div>
                  <div className="info">(it operations)</div>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-title"> moderator</div>
          <div className="row justify-content-center mt-5">
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgModerator1}
                    alt={imgModerator1}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day" style={{ bottom: "6%" }}>
                  <div className="date">Pangrukti Kisbandini</div>
                  <div className="info"></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="frame-dresscode">
                <div className="frame">
                  <img
                    src={imgModerator2}
                    alt={imgModerator2}
                    className="img"></img>
                  <img src={frame} alt={frame} className="frame"></img>
                </div>
                <div className="wrapper-day" style={{ bottom: "6%" }}>
                  <div className="date">Aris Sunandar</div>
                  <div className="info"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

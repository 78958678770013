import React, { useEffect, useState } from "react";
import "../assets/scss/main/main.css";
import { Hero } from "../part/home/Hero";

import { Galery } from "../part/home/Galery";
import { Maps } from "../part/home/Maps";
import { Rundown } from "../part/home/Rundown";
import { Dresscode } from "../part/home/Dresscode";
import { InfoPeserta } from "../part/home/InfoPeserta";
import { Reimburse } from "../part/home/Reimburse";
import { AgendaDay1 } from "../part/home/AgendaDay1";
import { AgendaDay2 } from "../part/home/AgendaDay2";
import { Komite } from "../part/home/Komite";
import { About } from "../part/home/About";
import { Nav } from "../components/Nav";
import { Footer } from "../components/Footer";
import { useMediaQuery } from "react-responsive";
import bgcenter from "../../src/assets/image/bg-bottom-hero.png";
import bgcentermobile from "../../src/assets/image/bg-center-mobile.png";
import { get_DataHome } from "../actions";

export function Home() {
  const isPhoneScreen = useMediaQuery({ query: "(max-width: 768px)" });
  const isDesktopScreen = useMediaQuery({ query: "(min-width: 768px)" });

  const [ onLoad, setLoad ] = useState(true);
  const [dataEvent, setDataEvent] = useState({})
  const [aboutText, setAbout] = useState("")
  const [galeriObj, setGaleriObj] = useState([]);
  const [dayOne, setDayOne] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [dayTwo, setDayTwo] = useState([]);
  const [dayThree, setDayThree] = useState([]);
  const [listMsa, setMsa] = useState([]);
  const [listDressCode, setDressCode] = useState([]);
  const [listRundown, setRundown] = useState([]);

  const getHome = () => {
    setLoad(true)
    get_DataHome({}, (res) => {
      const {event, content, galeri, day_1, speakers, day_2, day_3, msa, dress_code, rundown} = res
      setDataEvent(event)
      setAbout(content)
      setGaleriObj(galeri)
      setDayOne(day_1)
      setSpeakers(speakers)
      setDayTwo(day_2)
      setDayThree(day_3)
      setMsa(msa)
      setDayThree(day_3)
      setDressCode(dress_code)
      setRundown(rundown)

      setLoad(false)
    }, () => {

    })
  }

  useEffect(() => {
    getHome();
  }, [])

  return (
    <>
      <Nav></Nav>
      <div className="wrapper-hero">
        <Hero></Hero>
        {isDesktopScreen && <About content={aboutText} onLoad={onLoad}></About>}
      </div>

      <div className="bg-bottom-hero">
        {isDesktopScreen && (
          <img src={bgcenter} alt={bgcenter} className="bg-center"></img>
        )}
        {isPhoneScreen && (
          <img
            src={bgcentermobile}
            alt={bgcentermobile}
            className="bg-center"></img>
        )}
        {isPhoneScreen && <About content={aboutText} onLoad={onLoad}></About>}
        <Galery onLoad={onLoad} list={galeriObj}></Galery>
        {!onLoad ? <Maps event={dataEvent}></Maps> : '' }
        <Rundown onLoad={onLoad} list={listRundown}></Rundown>
        <AgendaDay1 onLoad={onLoad} dayOne={dayOne} dayTwo={dayTwo} speakers={speakers}></AgendaDay1>
        <Komite></Komite>
        <AgendaDay2 onLoad={onLoad} dayThree={dayThree} listMsa={listMsa}></AgendaDay2>
        <Dresscode onLoad={onLoad} dressCode={listDressCode}></Dresscode>
        <InfoPeserta onLoad={onLoad} event={dataEvent}></InfoPeserta>
        <Reimburse type="home"></Reimburse>
      </div>
      <Footer></Footer>
    </>
  );
}

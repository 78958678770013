import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import frame from "../../assets/image/frame-rundown.png";
import close from "../../../src/assets/image/icon-close.svg";
import { cksClient } from "../../helpers";
import withReactContent from "sweetalert2-react-content";
import warningIcon from '../../assets/image/info-circle.svg'
import Swal from "sweetalert2";
import { QrScanner } from "@yudiel/react-qr-scanner";

export function Reimburse({type}) {
  const [data, setData] = useState("");
  const [modalBackdrop, setBackdrop] = useState(false);
  const [modal, setModal] = useState(false);
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const toggleModal = (e, value) => {
    setModal(!modal);
    setBackdrop(!modalBackdrop);
  };

  const addReimburse = (type) => {
    var kode_unik = cksClient().get('_unq', {path: '/'});
    cksClient().set("_rimtp", type);
    setData('');

    if (kode_unik) {
      navigate("/reimburse")
    } else {
      MySwal.fire({
        html: `
        <div><img src="${warningIcon}" style="width: 120px;"></img></div>
        <div class="mt-3 text">
          <b>Sebelum melanjutkan silakan scan QR Code anda</b>
        </div>`,
        confirmButtonText: "Scan",
        background: "#0E2C4E",
        color: "#fff",
        padding: "2em",
        confirmButtonColor: "#26B2C3",
        customClass: {
          confirmButton: "mt-4 button-alert",
        },
      }).then(() => {
        toggleModal();
      })
    }
  }

  useEffect(() => {
    if (data) {
      var split = data.split("/");
      const kodeUnik = split[split.length - 1];
      // cksClient().set('_unq', )
      cksClient().set('_unq', kodeUnik, {expires: new Date(Date.now()+2592000), path: '/'})
      toggleModal();
      navigate("/reimburse")
    }
  }, [data])

  return (
    <>
      {modalBackdrop && <div className="modal-backdrop"></div>}
      {modal && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "592px" }}>
            <div className="modal-content">
              <button
                onClick={toggleModal}
                data-bs-dismiss="modal"
                aria-label="Close"
                className="close-modal"
                type="button">
              <img src={close} alt={close}></img>
              </button>

              <div className="modal-body p-0">
                {modal & !data ?
                <>
                {/* <QrReader
                  onResult={(result, error) => {
                    if (!!result) {
                      setData(result?.text);
                    }

                    if (!!error) {
                      console.info(error);
                    }
                  }}
                  style={{ width: "100%" }} /> */}
                  <QrScanner
                    onDecode={(result) => {
                      setData(result);
                    }}
                    onError={(error) => console.log(error?.message)}
                />
                </>
                 : '' }
              </div>
            </div>
          </div>
        </div>
      )}
      <section id="reimburse" className={type == 'profile' ? 'pt-4' : '' }>
        <div className="container">
          <div className="">
            <div className="title text-center">Reimburse</div>
            <div className="row justify-content-center mt-5">
              <div className="col-12 col-md-3">
                <div className="frame" style={{ cursor: "pointer" }} onClick={() => addReimburse('spd')}>
                  <div className="text">SPD</div>
                  <img src={frame} alt={frame} className="img"></img>
                </div>
              </div>
              <div className="col-12 col-md-3">
                <div className="frame" style={{ cursor: "pointer" }} onClick={() => addReimburse('transportasi')}>
                  <div className="text">TRANSPORTASI</div>
                  <img src={frame} alt={frame} className="img"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
